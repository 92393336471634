import * as Z from '@iconbuild/izzy';
import { GetProjectQuery } from '@/generated/hasura.graphql';
import { Router, RouteComponentProps } from '@reach/router';
import App from '@templates/app';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Button from '@atoms/button';
import Materials from './materials';
import SiteDetails from './site-details';
import Project3DPreview from './project-3d-preview';
import ProjectPreview from './project-preview';
import { EditMaterialSpeeds, MaterialPlanningSettings } from './material-edit';
import GCode from './g-code';

// Project data from database that is not stored in the main JSON string
// with the site plan data. Mainly labels for display in UI.

export interface ProjectData {
  label: string;
  site: string;
}

const DEFAULT_PROJECT_DATA = {
  latitudeDegrees: 30.216559,
  longitudeDegrees: -97.761057,
  orientationDegrees: 120,
  foundationWidthInches: 336,
  foundationLengthInches: 286,
  items: [],
};

const TitleBarActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 48px;
`;

const PanelSection = styled.div`
  &:first-child {
    margin-top: 16px;
  }
  margin: 48px 0;
`;

const ProjectEditor = ({
  back,
  page,
  title,
  project,
}: {
  back: () => void;
  refresh: () => void;
  page: string;
  title: string;
  project: GetProjectQuery['project_by_pk'];
}) => {
  const [materialEstimateData, setMaterialEstimateData] = useState<
    MaterialPlanningSettings
  >({
    startHeightInches: 0,
    endHeightInches: 126,
    printSpeedInchesPerSecond: 8,
    travelSpeedInchesPerSecond: 8,
    volumePerSupersack: 0.91,
    averageBatchWeight: 130,
    layerHeightInches: 0.75,
  });

  // Local state copy of source data for the project, parsed from the original
  // input from the database. We edit this data in state until ready to save,
  // then save it back to the database once the user hits the save button.
  const [sourceData, setSourceData] = useState(
    JSON.parse(
      project?.source_data || JSON.stringify(DEFAULT_PROJECT_DATA),
    ) as Z.SitePlanSourceData,
  );

  const sitePlan = JSON.parse(project?.site_plan || '{}');

  // Local state copy of database values for project, so we can edit them in
  // state until ready to save.
  const [projectData, setProjectData] = useState<ProjectData>({
    label: project?.label || 'New Project',
    site: project?.site || 'The Lab',
  });

  // Save original input data to state any time we get updated project data.
  useEffect(() => {
    setProjectData({
      label: project?.label || 'New Project',
      site: project?.site || 'The Lab',
    });
  }, [project]);

  // Re-Calculate the Start / End Inches for Material Planning.
  useEffect(() => {
    if (!sitePlan) return;
    const levels = Z.sitePlanLevels(sitePlan);
    const startHeightInches = Math.min(
      ...levels.map((level) => level.startHeightInches),
    );
    const endHeightInches = Math.max(
      ...levels.map((level) => level.endHeightInches),
    );
    setMaterialEstimateData({
      ...materialEstimateData,
      startHeightInches,
      endHeightInches,
    });
  }, [!sitePlan]);

  const studioProjectId = project?.buildos_project_id ?? project?.id;
  const viewInStudioButton = (
    <TitleBarActions>
      {studioProjectId && (
        <Button
          onClick={() =>
            window.open(
              `https://studio.iconbuild.com/designs/${studioProjectId}`,
              '_blank',
            )
          }
        >
          View in Studio
        </Button>
      )}
    </TitleBarActions>
  );

  // Render Materials Panel.
  const materialsPanel = (
    <EditMaterialSpeeds
      sitePlan={sitePlan}
      props={materialEstimateData}
      updateMaterialPlanningSettings={setMaterialEstimateData}
    />
  );

  // Render Edit Panel.
  const editPanel = (
    <>
      <PanelSection>{page === 'materials' && materialsPanel}</PanelSection>
      <PanelSection>
        <SiteDetails
          projectData={projectData}
          setProjectData={setProjectData}
          sourceData={sourceData}
          setSourceData={setSourceData}
        />
      </PanelSection>
    </>
  );

  const Route = ({
    component,
  }: RouteComponentProps & { component: React.ReactElement }) => component;

  return (
    <App
      section="projects"
      title="Projects"
      subtitle={title}
      onNavBack={back}
      panel={editPanel}
      action={viewInStudioButton}
      subNav={[
        {
          label: 'Levels',
          selected: page === 'levels',
          link: `/projects/${project?.id}`,
        },
        {
          label: '3D',
          selected: page === '3d',
          link: `/projects/${project?.id}/3d`,
        },
        {
          label: 'Material Planning',
          selected: page === 'materials',
          link: `/projects/${project?.id}/materials`,
        },
        {
          label: 'G-Code',
          selected: page === 'g-code',
          link: `/projects/${project?.id}/g-code`,
        },
      ]}
    >
      <Router>
        <Route
          path="/materials"
          component={
            <Materials sitePlan={sitePlan} props={materialEstimateData} />
          }
        />
        <Route
          path="/"
          component={<ProjectPreview sitePlan={sitePlan} title={title} />}
        />
        <Route
          path="/3d"
          component={<Project3DPreview plan={sitePlan} title={title} />}
        />
        {project && (
          <Route
            path="/g-code"
            component={
              <GCode sitePlan={sitePlan} title={title} project={project} />
            }
          />
        )}
        <Route default component={<div>Not found</div>} />
      </Router>
    </App>
  );
};

export default ProjectEditor;
